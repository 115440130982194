.authContainer {
  max-width: 26em;
  margin: auto;
  text-align: center;
}

.logoContainer {
  padding-top: 2.5em;
  img {
    width: 6em;
    height: 6em;
    margin: auto;
    display: block;
    border-radius: 100px;
  }
}

.googleSSO {
  margin: 3em auto;
  display: table;
}
